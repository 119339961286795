import React, { FunctionComponent } from "react";

import AniLink from "gatsby-plugin-transition-link/AniLink";

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <div>
      <div className="w-full">
        {/* <div className="flex bg-white h" style={{ height: "400px" }}> */}
        <div className="flex bg-white lg:h-52">
          <div className="flex pt-16 lg:items-center text-left lg:text-left px-8 md:px-28 lg:w-4/5">
            <div>
              <p className="text-3xl font-semibold text-bytestrone-blue md:text-4xl">
                Privacy Policy
              </p>
              <p className="mt-2  text-gray-900 ">
                Bytestrone values individual privacy and we want to give our
                Site’s visitors the opportunity to know what information we
                collect about them and how it is treated by us.
              </p>
            </div>
          </div>s
        </div>
      </div>
      <div className="flex bg-white">
        <div className="flex flex-col items-center text-left md:text-left px-8 sm:pt-0 pb-12 md:px-28 lg:w-4/5 ">
          <div>
            <p className=" text-gray-900 space-y-3">
              <p>
                This Privacy Policy (this “Policy”), effective as of August 1
                <sup>st</sup>, 2021, explains:
              </p>
              <div className="px-12">
                <ol className="list-decimal">
                  <li>Which information about you we collect.</li>
                  <li>
                    Purposes of using your personal information and legal basis.
                  </li>
                  <li>Retention of your personal information.</li>
                  <li>Disclosures of personal information.</li>
                  <li>
                    Where is your personal information stored and which are your
                    access rights.
                  </li>
                  <li>Contact us.</li>
                </ol>
              </div>
              <p>
                <strong>1. Information about you we collect.</strong>
              </p>

              <p>
                <strong>
                  a) Information collected automatically when you visit our
                  site:
                </strong>
              </p>

              <p>
                When navigating our websites and accessing or using our
                Services, Bytestrone will collect and store Cookies. If you
                would like more information, please visit our{" "}
                <AniLink to="/cookie-policy" className="text-blue-500">
                  cookie policy
                </AniLink>
                {/* <a className="text-blue-500" href="https://www.bytestrone.com/cookie-policy">
                  cookie policy 
                </a> */}{" "}
                page.
              </p>

              <p>
                Bytestrone will also collect and store Cookies. “Cookies” are
                text files placed in your computer’s browser to store certain
                preferences and information, which may enhance your browsing
                experience on Bytestrone’s websites. Cookies may be used to
                personalize your website experience, such as recognizing you by
                name when you return. Bytestrone does not use Cookies to track
                or collect personal identification information from website
                users. If you desire, instructions for your browser will allow
                you to make certain settings on your computer to warn you before
                a Cookie is stored, block all Cookies or erase Cookies from your
                hard drive.
              </p>

              <p>
                <strong>
                  b) Information is collected when you volunteer to identify
                  yourself, and provide requested information:
                </strong>
              </p>

              <p>
                You may choose to write to us, or fill out certain forms or
                online data requests, which provide your name, telephone number,
                e-mail address, job and related information.
              </p>

              <p>
                <strong>
                  2. Purposes of using your personal information and legal
                  basis.
                </strong>
              </p>

              <p>
                Bytestrone uses the information collected from you to: (i) to
                respond to your inquiries; (ii) consider your request or
                application, (iii) send you appropriate information in regards
                to news, events, job openings and related information you
                subscribed to, and (iv) for direct marketing purposes, including
                to send you newsletters, client alerts and information we think
                may interest you. Bytestrone may also use such data for market
                and consumer studies regarding product types, demographics and
                consumer preferences.
              </p>

              <p>
                We are entitled to use your personal information based on (i)
                our legitimate interest such as for research and development, to
                market and promote our services, to protect our legal rights and
                interests, to the extent that your interests or your fundamental
                rights are not overridden; (ii) your consent to do so for a
                specific purpose; or (iii) to process your data to comply with a
                legal obligation.
              </p>

              <p>
                <strong>3. Retention of your personal information.</strong>
              </p>

              <p>
                Bytestrone will retain your personal information for as long as
                necessary to fulfill the purposes for which it was collected or
                as necessary to comply with our legal obligations, resolve
                disputes, and maintain appropriate business records. Once the
                purpose has been fulfilled your personal information will be
                retained and blocked up due to the possibility of legal and
                governmental requirements and until their status of limitations
                have run out.
              </p>

              <p>
                <strong>4. Disclosures of personal information.</strong>
              </p>

              <p>
                Bytestrone may share your personal information with affiliated
                companies.
              </p>

              <p>
                In addition, Bytestrone reserves the right to disclose
                information where required by law or to comply with valid legal
                process (such as a search warrant, subpoena or court order) as
                well as to protect Bytestrone’s rights or property, including
                without limitation in the event of a transfer of control of
                Bytestrone or substantially all of its assets, or during
                emergencies when safety is at risk.
              </p>

              <p>
                <strong>
                  5. Where is your personal information stored and which are
                  your access rights.
                </strong>
              </p>

              <ul>
                <li>
                  <strong>Storage of the Information</strong>
                </li>
              </ul>
              <p>
                All of the information collected by Bytestrone through the
                Website is stored on secured servers, to protect against
                unauthorized use and access.
              </p>

              <ul>
                <li>
                  <strong>Your access rights.</strong>
                </li>
              </ul>
              <p>
                You can exercise your rights of access, rectification, erasure,
                restriction, opposition, personal information portability, and /
                or withdrawal of consent regarding your personal information
                which is stored in our server by email to the following address:{" "}
                <a className="text-blue-500" href="mailto:info@bytestrone.com">
                  info@bytestrone.com
                </a>
              </p>

              <ul>
                <li>
                  <strong>Effective Date of Privacy Notice</strong>
                </li>
              </ul>
              <p>
                This privacy notice was released as of August 1<sup>st</sup>,
                2021. It may be updated from time to time without notice to you.
              </p>

              <p>
                <strong>6. Contact Us</strong>
              </p>

              <p>
                If you have questions or concerns regarding the way in which
                your personal data is being processed or this Policy, please
                email us at{" "}
                <a className="text-blue-500" href="mailto:info@bytestrone.com">
                  info@bytestrone.com
                </a>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
